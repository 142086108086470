div#background{
    width: 100vw;
    height: 100vh;
    background: #2c3945 no-repeat 50%;
    background-size: cover;
}

div#card{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:linear-gradient(45deg, RGBA(44, 57, 69,.9), RGBA(44, 57, 69,.85), RGBA(44, 57, 69,.8), RGBA(44, 57, 69,.85), RGBA(44, 57, 69,.9));
    box-shadow: 2px 2px 8px rgba(0,0,0,0.05);
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

div#card>div{
    width: 330px;
    margin: 15px;
}

div#name{
    font-size: 1.2em;
}

div#buttons{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
}

div#footer{
    color: #DDDDDD;
    font-style: italic;
    font-size: .8em;
}

img#avatar{
    width: 160px;
    height: 160px;
    margin: auto;
    border-radius: 120px;
    object-fit: cover;
}

img#powered_by{
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    margin-bottom: -.25em;
}
